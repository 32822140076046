import {AppConfig} from "../ts/app/AppConfig";
import {BrowserEvents} from "../ts/constants/BrowserEvents";

import {WinMsg, WinMsgTypes} from "./WinMsg";
import createLogger from "../logger/createLogger";
import {SentryTags} from "../errorHandler/createSentryReport";

export interface IWCParams {
	targetWindow: Window;
	onMessage: (message: WinMsg) => void;
}

const log = createLogger("IWC", SentryTags.IWC);

/**
 * A helper function to log sent message.
 */
const logSentMessage = (targetWindow: Window, msg: WinMsg) => {
	const directionStr = " -> ";
	const targetWinName = targetWindow?.name?.trim() || "[?????]";
	const msgTimeStr = !msg.timeMS ? "" : `sent: ${msg.timeMS}, duration:${new Date().getTime() - msg.timeMS} ms. `;

	log.debug(`${window.name} ${directionStr} ${targetWinName} :: (${msg.type}) ${msgTimeStr}`, msg.data);
};

/**
 * This function will create sendMessage function that can be called to send any message
 * to given targetWindow parameter.
 * @param targetWindow - Window that will be sent message to
 */
export const createSendMessage = (targetWindow: Window) => {
	return (msg: WinMsg): void => {
		msg.timeMS = new Date().getTime();
		logSentMessage(targetWindow, msg);
		targetWindow.postMessage(msg, window.location.origin);
	};
};

/**
 * This function will create sendMessageWithType function that can be used instead of sendMessage when we want to have a finer
 * grouping of message, e.g. to help with message filtering in EMDR module.
 * @param targetWindow - Window that will be sent message to
 */
export const createSendMessageWithType = (targetWindow: Window) => {
	const sendMessage = createSendMessage(targetWindow);
	return <T>(messageType: WinMsgTypes) =>
		(data: T) => {
			sendMessage({type: messageType, data});
		};
};

/**
 * This function will add onMessage as a listener to incoming message across different window/tab.
 * Things to note:
 *   - This function will throws error if current url isn't whitelisted.
 *   - This function will ignore any message that isn't coming from target window.
 *   - Even for development purpose, this function won't log into console any incoming message.
 *     The logging itself, if needed, should be done inside onMessage.
 * @param onMessage - Function to be called whenever there's a safe incoming message.
 * @returns Unsubscribe function
 */
export const subscribeToInterWindowCommunication = ({targetWindow, onMessage}: IWCParams): (() => void) => {
	// Only allow listening if current url is whitelisted, or the portal is running in development mode.
	const whitelistedURL = AppConfig.IWC_URLs;
	if (!AppConfig.isDev && whitelistedURL.every((url) => url !== window.location.origin)) {
		const errorMessage = `Current domain ${window.location.href} was not found in the whitelist ${whitelistedURL}`;
		log.error(errorMessage);
		throw new Error(errorMessage);
	}

	// Any other message not coming from same origin will be ignored
	const isMessageSafe = (e: MessageEvent): boolean => {
		return e.source === targetWindow && e.origin === window.location.origin;
	};

	const handler = (e: MessageEvent): void => {
		if (!isMessageSafe(e)) {
			log.debug(
				`Unsafe message received! origin: ${e.origin}, iwcURL: ${
					window.location.origin
				}, (first 500 chars of) data: ${JSON.stringify(e.data).substr(0, 500)}`,
			);

			return;
		}

		onMessage(e.data);
	};

	window.addEventListener(BrowserEvents.MESSAGE, handler, false);
	return () => {
		window.removeEventListener(BrowserEvents.MESSAGE, handler);
	};
};

/**
 * Call this function when we want a complete package of subscribing and sending message.
 */
export const createIWC = ({targetWindow, onMessage}: IWCParams) => {
	const unsubscribe = subscribeToInterWindowCommunication({targetWindow, onMessage});
	const sendMessage = createSendMessage(targetWindow);
	const sendMessageWithType = createSendMessageWithType(targetWindow);
	return {
		unsubscribe,
		sendMessage,
		sendMessageWithType,
	};
};
