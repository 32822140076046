import {ContactTabPath, Param, Path, QueryString} from "app/Path";
import {generatePath} from "react-router";

const replaceToSlash = (path: string) => {
	return path.replace(/%2F/gi, "/");
};
/*
 * Client and Therapist tab menu
 */
export const getHashIdRoute = (path: string, hashId: string): string => {
	return generatePath(path, {[Param.USER_HASH_ID]: hashId});
};

export const getMenuPath = (path: string, dest: ContactTabPath): string => {
	return path.replace(`:${Param.TAB_MENU}`, dest);
};

export const getTabMenuRoute = (path: string, hashId: string, tabMenu: string): string => {
	return generatePath(path, {[Param.USER_HASH_ID]: hashId, [Param.TAB_MENU]: tabMenu});
};

export const getClientTabMenuRoute = (hashId: string, tabMenu: string): string => {
	return replaceToSlash(
		generatePath(Path.APP_CLIENT_DETAIL_MENU, {[Param.USER_HASH_ID]: hashId, [Param.TAB_MENU]: tabMenu}),
	);
};

export const getClientTabMenuRouteWithEvent = (hashId: string, tabMenu: string, eventString: string): string => {
	return `${getClientTabMenuRoute(hashId, tabMenu)}?${QueryString.Event}=${eventString}`;
};

export const getTherapistTabMenuRoute = (hashId: string, tabMenu: string): string => {
	return replaceToSlash(
		generatePath(Path.APP_THERAPIST_DETAIL_MENU, {[Param.USER_HASH_ID]: hashId, [Param.TAB_MENU]: tabMenu}),
	);
};

/*
 * Intervention route helper
 */
export const getInterventionPath = (menu: string): string => {
	return Path.APP_CLIENT_DETAIL_MENU_INTERVENTION.replace(`:${Param.INTERVENTION_MENU}`, menu);
};

export const getInterventionRoute = (hashId: string, interventionMenu: string): string => {
	return generatePath(Path.APP_CLIENT_DETAIL_MENU_INTERVENTION, {
		[Param.USER_HASH_ID]: hashId,
		[Param.INTERVENTION_MENU]: interventionMenu,
	});
};

/**
 * Intervention Custom Tracker Path
 * @param hashId
 * @param tracker
 * @returns /client/{clientHashId}/intervention/trackers/{trackerName}
 */
export const getInterventionTrackerRoute = (hashId: string, tracker: string) => {
	return generatePath(Path.APP_CLIENT_DETAIL_MENU_INTERVENTION_TRACKER, {
		[Param.USER_HASH_ID]: hashId,
		[Param.INTERVENTION_TRACKER]: tracker,
	});
};
