import {EmdrActions} from "../emdr/emdrActions";
import {OutgoingCallTypes} from "../call/callTypes";

/**
 * Types of inter-window communication messages
 */
export enum WinMsgTypes {
	USERLIST_UPDATE = "userlistUpdate",
	ACTIVE_CALL_UPDATE = "activeCallUpdate",

	// Token could not be signed
	HUNG_UP = "hup",

	// Check if video call window is ready
	CHECK_VIDEO_CALL_WINDOW = "CheckVideoCallWindow",

	INVITE_OTHER_PARTY = "callOtherParty",

	// Call SDK's streaming has started, i.e. the parties have started (seeing and) hearing each other
	STREAMING_STARTED = "streamingStarted",

	// This event will be fired once video call window IWC is ready
	VIDEO_CALL_WINDOW_READY = "VideoCallWindowIwcReady",

	// All emdr related WinMsg will use this as its type
	EMDR_MESSAGE = "EMDR_MESSAGE",

	// Twilio participant has left the call
	TWILIO_PARTICIPANT_LEFT = "twilioParticipantLeft",

	// Share Parent Analytics ID
	SHARE_PARENT_ANALYTICS_ID = "SHARE_PARENT_ANALYTICS_ID",

	PRIVATE_NOTE__MESSAGE = "privateNotesMessage",

	// Crash on call window
	CALL_WINDOW_CRASH = "callWindowCrash",

	// Check call type list to be shown
	CALL_TYPES_CHECK = "callTypesCheck",

	// Selected call type by user
	SELECTED_CALL_TYPE = "selectedCallType",

	// Call direction check before showing video call sreen
	CALL_DIRECTION_CHECK = "callDirectionCheck",

	// Per: https://github.com/senseobservationsystems/web-getgoalie/issues/4711,
	// we want to hide the sidebar on the main window when starting
	// screen sharing from the call window.
	HIDE_SIDEBAR = "hideSidebar",
}

interface CommonWinMsg {
	type: WinMsgTypes;
	data?: any;
	timeMS?: number; // This is only being used as part of logging exchanged WinMsg.
}

export interface EmdrWinMsg extends CommonWinMsg {
	type: WinMsgTypes.EMDR_MESSAGE;
	data: EmdrActions;
}

interface CallTypeMsg extends CommonWinMsg {
	type: WinMsgTypes.SELECTED_CALL_TYPE;
	data: OutgoingCallTypes;
}

export type WinMsg = CommonWinMsg | EmdrWinMsg | CallTypeMsg;
